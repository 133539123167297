import * as React from 'react';
import * as Redux from 'react-redux';

import { IReduxStore } from '../../types/redux';
import { HomeContainer } from '../HomeContainer';
import { ApplicationContext } from '../../utils/applicationContext';
import { IApplicationContext } from '../../types/application';

import * as styles from './app.css';
import { getProfiVacancies } from '../../actions/vacancies';

export interface IAppProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const App: React.FC<IAppProps> = ({ context, reduxStore: store }) => {
  React.useEffect(() => {
    store.dispatch(getProfiVacancies());
  }, [store]);

  return (
    <ApplicationContext.Provider value={context}>
      <Redux.Provider store={store}>
        {/* TODO убрать стили из контейнера */}
        <section className={styles['mainpage']}>
          <HomeContainer />
        </section>
      </Redux.Provider>
    </ApplicationContext.Provider>
  );
};
