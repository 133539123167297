import { IconArrowLeft16, UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './PromoLink.css';

export interface IPromoLinkProps {
  onClick?(): void;
  url: string;
}

export const PromoLink: React.VFC<IPromoLinkProps> = ({ onClick, url }) => {
  return (
    <a className={styles['container']} href={url} onClick={onClick} data-testid="PromoLink">
      <UIHeading4 color="black_100">
        Все акции и скидки
        <br />
        от банков и&nbsp;застройщиков
      </UIHeading4>
      <div className={styles['show-all']}>
        <UIText2 color="current_color">Смотреть предложения</UIText2>
        <div className={styles['icon']}>
          <IconArrowLeft16 color="primary_100" />
        </div>
      </div>
    </a>
  );
};
