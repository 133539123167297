import { IconArrowUpRight16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './SelectPopupLink.css';

interface ICommercialLinkProps {
  href: string;
  title: string;
  onClick?: VoidFunction;
}

export const SelectPopupLink: React.FC<ICommercialLinkProps> = ({ href, title, onClick }) => {
  return (
    <a href={href} className={styles['container']} onClick={onClick}>
      {title} <IconArrowUpRight16 color="current_color" />
    </a>
  );
};
