import * as React from 'react';

import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { ItemsCarousel } from '../ItemsCarousel';
import { PromoAttributes } from './components/PromoAttributes';
import { PromoItem } from './components/PromoItem';
import { PromoLink } from './components/PromoLink';
import * as styles from './NewbuildingPromoBlock.css';

export interface INewbuildingPromoBlockProps {
  subsidisedMortgageBanner: React.ReactNode;
  onPromoClick(promo: INewbuildingPromo, index: number): void;
  onPromoLinkClick?(): void;
  promos: INewbuildingPromo[];
  promosUrl: string;
}

export const NewbuildingPromoBlock: React.FC<INewbuildingPromoBlockProps> = ({
  subsidisedMortgageBanner,
  onPromoClick,
  onPromoLinkClick,
  promos,
  promosUrl,
}) => {
  const renderOutside = React.useCallback(() => {
    const items = promos.map((promo, idx) => {
      return (
        <li key={`promo-${idx}`}>
          <PromoItem
            key={promo.uniquePromoId}
            attributes={<PromoAttributes promo={promo} />}
            activeDateTo={promo.activeDateToText}
            highlighted={promo.isHighlighted}
            name={promo.promoName}
            providerLogo={promo.providerLogo}
            providerName={promo.providerName}
            onClick={() => onPromoClick(promo, idx)}
          />
        </li>
      );
    });

    items.unshift(subsidisedMortgageBanner as React.ReactElement);

    items.push(
      <li key="promo-link">
        <PromoLink url={promosUrl} onClick={onPromoLinkClick} />
      </li>,
    );

    return items;
  }, [onPromoClick, onPromoLinkClick, promos, promosUrl, subsidisedMortgageBanner]);

  return (
    <section className={styles['container']} data-testid="NewbuildingPromoBlock">
      <div className="cg-container-fluid-xs">
        <h2 className={styles['title']}>Акции и скидки</h2>
        <ItemsCarousel scrollVisible renderOutside={renderOutside} />
      </div>
    </section>
  );
};
