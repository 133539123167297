import { TColors } from '@cian/ui-kit';
import { ESalesType } from '../../../../types/salesLink';
import { TConstantColors } from '@cian/ui-kit/colors/constant';

export const getTitleColor = (salesType: ESalesType): TColors | TConstantColors => {
  switch (salesType) {
    case ESalesType.BlackFriday:
      return 'white_100';
    default:
      return 'black_100';
  }
};

export const getDescriptionColor = (salesType: ESalesType): TColors | TConstantColors => {
  switch (salesType) {
    case ESalesType.BlackFriday:
      return 'white_100';
    default:
      return 'gray60_100';
  }
};

export const getArrowColor = (salesType: ESalesType): TColors | TConstantColors => {
  switch (salesType) {
    case ESalesType.BlackFriday:
      return 'white_100';
    default:
      return 'primary_100';
  }
};
