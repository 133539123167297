export const DEAL_RENT_TRAP_MAINPAGE = 'deal_rent_trap_mainpage';

export const NEWBUILDING_PROMOS_IN_SEARCH = 'newbuilding_promos_in_search';

export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';

export const EXPERIMENTS = [
  DEAL_RENT_TRAP_MAINPAGE,
  NEWBUILDING_PROMOS_IN_SEARCH,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
];
