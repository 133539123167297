import { TThunkAction } from '../../../types/redux';
import { openDatesModal } from '../datesModal';
import { selectDatesModalState } from '../../../selectors/filters/datesModal/selectDatesModalState';
import { getDateRangeValue } from '../../../utils/jsonQuery';

export function dailyRentChecksBeforeSearch(): TThunkAction<boolean> {
  return (dispatch, getState, context) => {
    const state = getState();
    const {
      filters: { jsonQuery },
    } = state;
    const { config } = context;
    const isDatesModalAutoOpenRSEnabled = config.get<boolean>('Dailyrent.DatesAutoOpen.Enabled');
    const datesModalState = selectDatesModalState(state);
    const { gte, lt } = getDateRangeValue('dates')(jsonQuery) || {};
    const hasDatesInFilters = Boolean(gte && lt);

    const shouldOpenDatesModal = !datesModalState.wasOpenedAtLeastOnce && !hasDatesInFilters;
    const canPassSearch = !shouldOpenDatesModal;

    if (!isDatesModalAutoOpenRSEnabled) {
      return true;
    }

    if (shouldOpenDatesModal) {
      dispatch(openDatesModal());
    }

    return canPassSearch;
  };
}
