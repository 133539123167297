import { IApplicationState } from '../../../types/redux';
import { dealTypeFromJsonQuery, FDealType } from '../../../utils/category';
import { getDateRangeValue } from '../../../utils/jsonQuery';
import { selectDatesModalState } from './selectDatesModalState';

export function selectNeedExtraDailyrentParam(state: IApplicationState): boolean {
  const {
    filters: { jsonQuery },
  } = state;

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const isDailyRent = dealType === FDealType.RentDaily;
  const { gte, lt } = getDateRangeValue('dates')(jsonQuery) || {};
  const hasDatesInFilters = Boolean(gte && lt);
  const datesModalState = selectDatesModalState(state);

  return isDailyRent && datesModalState.wasOpenedAtLeastOnce && !hasDatesInFilters;
}
