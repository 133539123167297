import * as React from 'react';

import * as styles from './UsefulLink.css';
import { IconMap16, Button, UIText1 } from '@cian/ui-kit';

import mapImg from './assets/map.png';

interface IMapLinkProps {
  url: string;
  onClick(categoryName: string): void;
  categoryName: string;
}

export const MapLink: React.FC<IMapLinkProps> = ({ url, onClick, categoryName }) => {
  return (
    <a className={styles['container']} href={url} onClick={() => onClick(categoryName)}>
      <div className={styles['map-container']}>
        <img src={mapImg} width="322" height="100" />
        <div className={styles['map-button']}>
          <Button
            beforeIcon={<IconMap16 color="primary_100" />}
            fullWidth
            theme="fill_white_primary"
            onClick={() => onClick(categoryName)}
          >
            Смотреть на карте
          </Button>
        </div>
      </div>
      <UIText1>Ищите новостройки рядом с работой</UIText1>
    </a>
  );
};
