import { connect } from 'react-redux';

import { IApplicationState, TThunkAction } from '../../types/redux';
import { getMeta, persistFilters } from '../../actions/filters';
import { FiltersSearchButtons } from '../../components/FiltersSearchButtons';
import { formatOfferCount } from './utils/formatOfferCount';
import { selectPageKind } from '../../selectors/pageKind';
import { selectDisableRegionExpansion } from '../../selectors/regionExpansion';
import { EPageKind } from '../../types/page';
import { EMetaStatus, IGeocodeState } from '../../types/filters';
import { doChecksBeforeSearch } from '../../actions/filters/checksBeforeSearch/doChecksBeforeSearch';
import { skipDatesModalFlagForSearch } from '../../constants/filters/datesModal';
import { selectNeedExtraDailyrentParam } from '../../selectors/filters/datesModal/selectNeedExtraDailyrenParam';

interface IFiltersSearchButtonsContainerStateProps {
  pageKind: EPageKind;
  metaStatus: EMetaStatus;
  url: string;
  mapUrl: string;
  geocode: IGeocodeState;
  offerCount: string;
  disableRegionExpansion: boolean;
}

interface IFiltersSearchButtonsContainerDispatchProps {
  getMeta(): void;
  persistFilters(): void;
  doChecksBeforeSearch(): TThunkAction<boolean>;
}

interface IOwnProps {
  isDatesModalAutoOpenRSEnabled: boolean;
}

export function mapStateToProps(
  state: IApplicationState,
  ownProps: IOwnProps,
): IFiltersSearchButtonsContainerStateProps {
  const {
    filters: { meta },
  } = state;
  const { isDatesModalAutoOpenRSEnabled } = ownProps;

  const pageKind = selectPageKind(state);
  const disableRegionExpansion = selectDisableRegionExpansion(state);

  const offerCount = formatOfferCount({ offerCount: meta.count });

  // Временное решение переходного периода, пока даты не обязательны
  const needExtraDailyrentParam = isDatesModalAutoOpenRSEnabled && selectNeedExtraDailyrentParam(state);
  const separator = meta.url.includes('?') ? '&' : '?';
  const dailyrentDatesModalParam = needExtraDailyrentParam ? `${separator}${skipDatesModalFlagForSearch}` : '';

  return {
    pageKind,
    metaStatus: meta.status,
    url: `${meta.url}${dailyrentDatesModalParam}`,
    mapUrl: `${meta.mapUrl}${dailyrentDatesModalParam}`,
    geocode: state.filters.geocode,
    offerCount,
    disableRegionExpansion,
  };
}

export const mapDispatchToProps: IFiltersSearchButtonsContainerDispatchProps = {
  getMeta,
  persistFilters,
  doChecksBeforeSearch,
};

export const FiltersSearchButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(FiltersSearchButtons);
