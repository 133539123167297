import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { openPromoDetails } from '../../actions/newbuildingPromo';
import { NewbuildingPromoBlock } from '../../components/NewbuildingPromoBlock';
import { selectPromos } from '../../selectors/newbuildingPromo';
import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingPromosUrl } from './helpers/getNewbuildingPromosUrl';
import { trackDetailsClick, trackPromoShow, trackToPromoClick } from './tracking';
import { SubsidisedMortgageBannerContainer } from '../SubsidisedMortgageBannerContainer';

export const NewbuildingPromoBlockContainer: React.VFC = () => {
  const { config } = useApplicationContext();
  const promosUrl = getNewbuildingPromosUrl(config);
  const promos = useSelector(selectPromos);

  const dispatch = useDispatch();

  const handlePromoShow = React.useCallback(() => {
    trackPromoShow(promos);
  }, [promos]);

  const handlePromoClick = React.useCallback(
    (promo: INewbuildingPromo, index: number) => {
      dispatch(openPromoDetails(promo));
      trackDetailsClick({ promo, index });
    },
    [dispatch],
  );

  if (!promos.length) {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={handlePromoShow} triggerOnce>
      <NewbuildingPromoBlock
        subsidisedMortgageBanner={<SubsidisedMortgageBannerContainer key="subsidised-mortgage-banner" />}
        promos={promos}
        onPromoClick={handlePromoClick}
        onPromoLinkClick={trackToPromoClick}
        promosUrl={promosUrl}
      />
    </ActionAfterViewObserver>
  );
};
