import * as React from 'react';

import { NewbuildingPromoBlockContainer } from '../NewbuildingPromoBlock';
import { NewbuildingPromoModalLazy } from '../NewbuildingPromoModal';

export const TopNewbuildingBlockContainer: React.VFC = () => {
  return (
    <>
      <NewbuildingPromoBlockContainer />
      <NewbuildingPromoModalLazy />
    </>
  );
};
