import * as React from 'react';

import { UsefulLinks, UsefulLink, MapLink } from '../../components/UsefulLinks';
import { useSelector } from 'react-redux';
import { selectUsefulLinks } from '../../selectors/usefulLinks';
import { selectPageSection } from '../../selectors/pageSection';
import { ESectionType } from '../../types/sectionType';
import { selectMapUrl } from '../../selectors/filters/mapUrl';
import { trackUsefulLinkClick, trackUsefulLinksShow } from './tracking';

export const UsefulLinksContainer: React.FC = () => {
  const usefulLinks = useSelector(selectUsefulLinks);
  const section = useSelector(selectPageSection);
  const mapUrl = useSelector(selectMapUrl);

  const onShow = React.useCallback(() => {
    trackUsefulLinksShow(section);
  }, [section]);
  const onClick = React.useCallback(
    (categoryName: string) => {
      trackUsefulLinkClick(section, categoryName);
    },
    [section],
  );

  const linksElements = React.useMemo(() => {
    const elements = usefulLinks.map(({ imageUrl, seoUrl, title, gaLabel }, index) => (
      <UsefulLink
        key={`useful-link-${index}`}
        imageUrl={imageUrl}
        url={seoUrl}
        title={title}
        onClick={onClick}
        categoryName={gaLabel as string}
      />
    ));

    if (section === ESectionType.Index) {
      elements.push(<MapLink onClick={onClick} key="useful-link-map" url={mapUrl} categoryName="showOnMap" />);
    }

    return elements;
  }, [usefulLinks, section, onClick, mapUrl]);

  return <UsefulLinks onShow={onShow}>{linksElements}</UsefulLinks>;
};
