import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsNewbuildingPromosInSearchExperiment } from '../../selectors/experiments/selectIsNewbuildingPromosInSearchExperiment';
import { selectJsonQuery } from '../../selectors/filters/selectJsonQuery';
import { dealTypeFromJsonQuery, FDealType, FOfferType, offerTypeFromJsonQuery } from '../../utils/category';
import { FilterPriceContainer } from '../FilterPriceContainer';

export const FilterPriceChooserContainer: React.FC = () => {
  const isNewbuildingPromosInSearchExperiment = useSelector(selectIsNewbuildingPromosInSearchExperiment);
  const jsonQuery = useSelector(selectJsonQuery);

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const isPriceAndPaymentFilterVisible =
    isNewbuildingPromosInSearchExperiment && dealType === FDealType.Sale && offerType === FOfferType.Flat;

  if (isPriceAndPaymentFilterVisible) {
    return <div data-testid="FilterPriceAndPaymentContainer">Filter Price and Payment</div>;
  }

  return <FilterPriceContainer />;
};
