import { IApplicationState } from '../../types/redux';
import { getExperimentGroup } from '../../utils/getExperimentGroup';
import { selectExperiments } from './selectExperiments';
import { NEWBUILDING_PROMOS_IN_SEARCH } from '../../constants/experiments';

export function selectIsNewbuildingPromosInSearchExperiment(state: IApplicationState): boolean {
  const experiments = selectExperiments(state);

  return getExperimentGroup(experiments, NEWBUILDING_PROMOS_IN_SEARCH) === 'enabled';
}
